import createRequest from '../utils/request-ip'
import API_PREFIX from '@/config/api.prefix'
const request = createRequest(API_PREFIX.ip_parse_url)

/* IP地址查询接口 */
export function  getIpInfo(data) {
  return request({
    url: '/?global/get_ip_info',
    method: 'post',
    data
  })
}