import axios from 'axios'
import API_PREFIX from '../config/api.prefix'
import {message} from 'ant-design-vue'
import {getPhpSign} from './tools'
import apiKeys from '@/config/api.key'

function createRequest(baseURL, ipKey = apiKeys.ipKey) {
    // 创建axios实例
    const request = axios.create({
        // API 请求的默认前缀
        baseURL: baseURL || API_PREFIX.ip_parse_url,
        // 请求超时时间
        timeout: 10000,
    })

    request.interceptors.request.use(config => {
        // 在发送请求之前做些什么
        // console.log(config)

        let data = switchParams(config.data)
        config.data = {
            ...data
        }
        let sign = getPhpSign(config.data, ipKey)
        config.data = {
            ...config.data,
            sign
        }
        console.log(config)
        return config
    }, error => {
        // 对请求错误做些什么
        return Promise.reject(error)
    });

    request.interceptors.response.use(response => {
        // 对响应数据做点什么
        if (response.data.error_code !== '0') {
            message.error(response.data.error_msg || '网络异常，请稍后重试')
            return Promise.reject(response.data)
        }
        return response.data
    }, error => {
        // 对响应错误做点什么
        message.error('网络异常，请稍后重试')
        return Promise.reject(error)
    })

    return request
}

function switchParams(params) {
    // console.log('switchParams', params)
    let paramsObj = {}
    for (let key in params) {
        let value = params[key]
        if (value !== '' || value !== null || value !== undefined) {
            paramsObj[key] = value
        }
    }
    return paramsObj
}


export default createRequest

