<template>
  <div class="mp-detail-module">
    <div class="module-top">
      <h3 class="mod-ttile">登录日志</h3>
    </div>
    <div class="module-main">
      <!-- 搜索栏 -->
      <div class="mp-table-search-box">
        <a-form-model layout="inline"
          :form="searchForm"
          @submit="handleSearchSubmit"
          @submit.native.prevent
        >
          <a-form-model-item>
            <a-range-picker v-model="searchForm.loginTimeRange" style="width: 220px"
              :getCalendarContainer="node => node.parentNode || document.body"
            >
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </a-form-model-item>
          <a-form-model-item>
            <a-select style="width: 104px" placeholder="登录方式" v-model="searchForm.loginWay"
              :getPopupContainer="node => node.parentNode || document.body"
            >
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="sms">
                验证码登录
              </a-select-option>
              <a-select-option value="pwd">
                密码登录
              </a-select-option>
            </a-select>
          </a-form-model-item>    
          <a-form-model-item>
            <a-select style="width: 104px" placeholder="登录结果" v-model="searchForm.loginResult"
              :getPopupContainer="node => node.parentNode || document.body"
            >
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="y">
                成功
              </a-select-option>
              <a-select-option value="n">
                失败
              </a-select-option>
            </a-select>
          </a-form-model-item>    
          <a-form-model-item>    
            <a-button class="mp-grey-btn" html-type="submit">
              查询
            </a-button>
            <a-button class="mp-grey-btn ml-8" @click="resetSearchForm">
              重置
            </a-button>
          </a-form-model-item>    
        </a-form-model>
      </div>
    
      <!-- 列表 -->
      <div class="mp-table-wrap">
        <a-table :columns="columns" 
          :data-source="data" 
          rowKey="loginId"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >   
            <div slot="serial" slot-scope="text,record,index">{{index+1}}</div>
            <div slot="ip" slot-scope="text,record">
              <a-tooltip placement="top" trigger="click" @visibleChange="(visible) => visibleChange(visible, record)">
                <template slot="title">
                  <span>{{record.ip.loading ? '正在查询...' : record.ip.addressName}}</span>
                </template>
                <a>{{record.ip.address}}</a>
              </a-tooltip>
            </div>
        </a-table>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import {getIpInfo} from '@/api/ip'
import {loginLogList} from '@/api/user'
const columns = [
  {
    align: 'center',
    title: '序号',
    width: '80px',
    scopedSlots: { customRender: 'serial'}
  },
  {
    align: 'center',
    title: '登录时间',
    dataIndex: 'loginTime'
  },
  {
    align: 'center',
    title: '登录IP',
    dataIndex: 'ip',
		scopedSlots: { customRender: 'ip'}
  },
  {
    align: 'center',
    title: '登录方式',
    dataIndex: 'loginMethod',
  },
  {
    align: 'center',
    title: '登录结果',
    dataIndex: 'loginResult',
  }
]

export default {
  computed: {
    ...mapGetters(['adminId'])
  },
  data() {
    return {
      data: [],
      columns,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },

      // 搜索表单
      searchForm: {
        loginTimeRange: null,
        loginWay: '',
        loginResult: ''
      },
    }
  },
  created() {
    this.getListData()
  },
  methods: {
    getListData() {
      let startLoginTime = '', endLoginTime = ''
      try {
        if (this.searchForm.loginTimeRange && this.searchForm.loginTimeRange.length > 0) {
          startLoginTime = this.searchForm.loginTimeRange[0].format('YYYY-MM-DD')
          endLoginTime = this.searchForm.loginTimeRange[1].format('YYYY-MM-DD')
        }
      } catch (error) {
        console.warn(error)
        startLoginTime = ''
        endLoginTime = ''
      }
      this.loading = true
      loginLogList({
        current: this.pagination.current,
        size: this.pagination.pageSize,
        userId: this.$route.query.userId,
        startLoginTime,
        endLoginTime,
        loginWay: this.searchForm.loginWay,
        loginResult: this.searchForm.loginResult,
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.pagination.total = res.data.total || 0
          this.data = res.data.records.map(item => {
            return {
              ...item,
              loginMethod: item.loginMethod === 'pwd' ? '密码登录' : '验证码登录',
              loginResult: item.loginResult === 'y' ? '成功' : '失败',
              ip: {
                address: item.loginIp,
                status: '', // 1 - 查询成功 2 - 查询失败
                loading: false,
                addressName: ''
              }
            }
          })
        } else {
          this.$message.error(res.msg || '网络异常')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading = false
      })
    },
    // 切换分页等操作
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter)
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.getListData()
    },

    // 搜索表单
    handleSearchSubmit() {
      console.log(this.searchForm)
      this.pagination.current = 1
      this.getListData()
    },
    // 重置搜索
    resetSearchForm() {
      this.searchForm.loginTimeRange = null
      this.searchForm.loginWay = ''
      this.searchForm.loginResult = ''
      this.pagination.current = 1
      this.getListData()
    },
		visibleChange(visible, record) {
			console.log(visible)
			if (visible) {
        if (record.ip.status !== 1) {
          let reg = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/
          record.ip.loading = true
          if (!reg.test(record.ip.address)) {
            record.ip.loading = false
            record.ip.addressName = '无效的ip地址格式'
            return
          }
          // 国外的一个ip查询
          // http://ip-api.com/json/${ip}?lang=zh-CN

          // 淘宝
          // https://ip.taobao.com/outGetIpInfo?ip=${ip}&accessKey=alibaba-inc
          // 示例： https://ip.taobao.com/outGetIpInfo?ip=202.101.172.35&accessKey=alibaba
          // axois.get(`//ip-api.com/json/${record.ip.address}?lang=zh-CN`).then(res => {
          //   console.log(res)
          //   if (res.data && res.data.status === 'success') {
          //     record.ip.status = 1
          //     record.ip.loading = false
          //     let data = res.data
          //     record.ip.addressName = `${data.country} ${data.regionName} ${data.city} `
          //   } else {
          //     record.ip.status = 2
          //     record.ip.loading = false
          //     record.ip.addressName = '查询失败'
          //   }
          // }).catch(err => {
          //   record.ip.status = 2
          //   record.ip.loading = false
          //   record.ip.addressName = '查询失败'
          // })

          getIpInfo({ip: record.ip.address}).then(res => {
            // "area": "",
            // "area_id": "",
            // "city": "杭州",
            // "city_id": "330100",
            // "country": "中国",
            // "country_id": "CN",
            // "ip": "202.101.172.35",
            // "isp": "电信",
            // "isp_id": "100017",
            // "query_ip": "202.101.172.35",
            // "region": "浙江",
            // "region_id": "330000"
            record.ip.status = 1
            record.ip.loading = false
            let data = res.data
            record.ip.addressName = `${data.country} ${data.region} ${data.city}`
          }).catch(err => {
            console.warn(err)
            record.ip.status = 2
            record.ip.loading = false
            record.ip.addressName = '查询失败'
          })
        }
			}
		}
  },
}
</script>

<style scoped>

</style>